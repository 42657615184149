import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { PageHeader, Card } from 'antd';
import Content from '../../component/Content';
import MessageFeedback from './Message';
import MentorshipFeedback from './Mentorship';

import {
  fetchMentorshipFeedbacks,
  fetchMessageFeedbacks,
  messageFeedbacksSetPage,
  messageFeedbacksSetPageSize,
  mentorshipFeedbacksSetPage,
  mentorshipFeedbacksSetPageSize,
  openHubFromFeedbacks,
  resetFeedbacksState,
} from './actions';

class Feedback extends PureComponent {
  state = {
    key: 'message',
  };

  componentWillUnmount() {
    const { resetFeedbacksState } = this.props;
    resetFeedbacksState();
  }

  tabList = [
    {
      key: 'message',
      tab: 'Message',
    },
    {
      key: 'mentorship',
      tab: 'Mentorship',
    },
  ];

  getContentMap = () => {
    const {
      mentorship,
      message,
      fetchMentorshipFeedbacks,
      fetchMessageFeedbacks,
      messageFeedbacksSetPage,
      messageFeedbacksSetPageSize,
      mentorshipFeedbacksSetPage,
      mentorshipFeedbacksSetPageSize,
      openHubFromFeedbacks,
    } = this.props;
    return {
      message: (
        <MessageFeedback
          fetchData={fetchMessageFeedbacks}
          data={message}
          setPage={messageFeedbacksSetPage}
          setPageSize={messageFeedbacksSetPageSize}
          openHub={openHubFromFeedbacks}
        />
      ),
      mentorship: (
        <MentorshipFeedback
          fetchData={fetchMentorshipFeedbacks}
          data={mentorship}
          setPage={mentorshipFeedbacksSetPage}
          setPageSize={mentorshipFeedbacksSetPageSize}
          openHub={openHubFromFeedbacks}
        />
      ),
    };
  };

  onTabChange = (key, type) => {
    this.setState({ [type]: key });
  };

  render() {
    const contentMap = this.getContentMap();
    return (
      <Fragment>
        <PageHeader title="Message / Mentorship Feedback" />
        <Content>
          <Card
            style={{ width: '100%' }}
            tabList={this.tabList}
            activeTabKey={this.state.key}
            onTabChange={key => {
              this.onTabChange(key, 'key');
            }}
          >
            {contentMap[this.state.key]}
          </Card>
        </Content>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ feedback: { mentorship, message } }) => ({
  mentorship,
  message,
});

const mapDispatchToProps = {
  fetchMentorshipFeedbacks,
  fetchMessageFeedbacks,
  messageFeedbacksSetPage,
  messageFeedbacksSetPageSize,
  mentorshipFeedbacksSetPage,
  mentorshipFeedbacksSetPageSize,
  openHubFromFeedbacks,
  resetFeedbacksState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Feedback);
