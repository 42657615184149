import React from 'react';
import { Button, Card, Popconfirm, Table, Input, Tooltip } from 'antd';
import './analytics-profile.scss';

const { Search } = Input;

const AnalyticsProfile = ({
  hubs,
  syncHubUsers,
  removeDataFromCustomerIOHubSync,
  searchHubs,
  loadingForHubId,
  loading,
}) => {
  const deleteFromCustomerIO = obj => {
    removeDataFromCustomerIOHubSync(obj);
  };

  if (!hubs) {
    return <div>No hubs with customer io enabled.</div>;
  }
  const columns = [
    {
      title: 'Hub Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (t, r) => {
        const { customerioTrackingStatus } = r;
        const isDisabled = ['syncStarted', 'unsyncStarted'].includes(
          customerioTrackingStatus
        );
        return (
          <div className="analytics-profile--row-actions">
            {['syncError', 'unsyncError'].includes(customerioTrackingStatus) ? (
              <Tooltip
                title={` Error in ${
                  customerioTrackingStatus === 'syncError' ? 'sync' : 'unsync'
                } process. Please contact Engineering team or try again.`}
              >
                <span className="analytics-profile--row-actions__error">
                  {customerioTrackingStatus === 'syncError' ? 'Sync' : 'Unsync'}{' '}
                  process error.
                </span>
              </Tooltip>
            ) : null}
            {isDisabled ? (
              <>
                <Tooltip title="Sync/Unsync in progress">
                  <Button type="primary" size="small" disabled>
                    Sync Users
                  </Button>
                </Tooltip>
                <Tooltip title="Sync/Unsync in progress">
                  <Button type="primary" size="small" disabled>
                    Unsync Users
                  </Button>
                </Tooltip>
              </>
            ) : (
              <>
                <Popconfirm
                  title={
                    <>
                      <div className="analytics_profile__popover__header">
                        Are you sure you want to sync all users in Customer.io?
                      </div>
                    </>
                  }
                  onConfirm={() => syncHubUsers({ hubId: r.id })}
                >
                  <Button
                    type="primary"
                    size="small"
                    loading={loadingForHubId === r.id}
                  >
                    Sync Users
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title={
                    <>
                      <div className="analytics_profile__popover__header">
                        Are you sure you want to unsync all users from
                        Customer.io?
                        <br />
                        This will also disable the &apos;
                        <u>Customer IO Tracking</u>&apos; flag (
                        <u>Enable Module Settings &gt; Customer.io Settings</u>
                        ).
                      </div>
                    </>
                  }
                  onConfirm={() => deleteFromCustomerIO({ hubId: r.id })}
                >
                  <Button
                    type="primary"
                    size="small"
                    loading={loadingForHubId === r.id}
                  >
                    Unsync Users
                  </Button>
                </Popconfirm>
              </>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div className="analytics-profile-container">
      <Search
        placeholder="Search by hub name or hub id"
        enterButton="Search"
        size="large"
        onSearch={value => searchHubs(value)}
      />
      <Card className="analytics-profile--warning">
        <div className="analytics-profile--warning__description">
          <span>Note: </span>
          Sync/Unsync process for a given hub is performed in batches. The data
          for the &apos;joined&apos; users of the hub may not reflect instantly
          in Customer.io and may take upto 30 minutes.
          <br />
          This page only shows the V2 hubs for which &apos;Customer IO
          Tracking&apos; feature flag is enabled.
          <br />
          Please ensure that you wait atleast 90 minutes before performing
          unsync operation after a sync operation.
        </div>
      </Card>
      <div className="analytics-profile--table">
        <Table
          loading={loading}
          columns={columns}
          dataSource={hubs.map(el => ({ ...el, key: el.id }))}
        />
      </div>
    </div>
  );
};

export default AnalyticsProfile;
