const MenuData = [
  {
    to: '/',
    key: 'home',
    icon: 'dashboard',
    title: 'Home',
    accessGroups: ['home'],
  },
  {
    to: '/standard-hub-management',
    key: 'standard-hub-management',
    icon: 'desktop',
    title: 'V2 Hub Management',
    accessGroups: ['v2-hub-management'],
  },
  {
    to: '/approve-deleted-hubs',
    key: 'approve-deleted-hubs',
    icon: 'desktop',
    title: 'Approve Deleted Hubs',
    accessGroups: ['delete-hub-access'],
  },
  {
    to: '/access-management',
    key: 'access-management',
    icon: 'lock',
    title: 'Access Management',
    accessGroups: ['access-management'],
  },
  {
    title: 'Tools',
    key: 'tools',
    icon: 'tool',
    subMenu: [
      {
        isInternal: true,
        to: '/clusters',
        key: 'clusters',
        icon: 'cluster',
        title: 'Cluster Management',
        accessGroups: ['cluster-management'],
      },
      {
        isInternal: true,
        to: '/hubs',
        key: 'hubs',
        icon: 'desktop',
        title: 'Hub Management',
        accessGroups: ['hub-management'],
      },
      {
        isInternal: true,
        to: '/schools',
        key: 'schools',
        icon: 'reconciliation',
        title: 'Schools',
        accessGroups: ['schools'],
      },
      {
        isInternal: true,
        to: '/file-upload',
        key: 'file-upload',
        icon: 'file-add',
        title: 'File Upload',
        accessGroups: ['file-upload'],
      },
      {
        isInternal: true,
        to: '/icon-set',
        key: 'icon-set',
        icon: 'file-image',
        title: 'Icon Set',
        accessGroups: ['icon-set'],
      },
      {
        isInternal: true,
        to: '/automated-marketing',
        key: 'automated-marketing',
        icon: 'mail',
        title: 'Automated Marketing',
        accessGroups: ['automated-marketing'],
      },
      {
        isInternal: true,
        to: '/campaigns',
        key: 'campaigns',
        icon: 'sound',
        title: 'Campaigns',
        accessGroups: ['campaigns'],
      },
      {
        isInternal: true,
        to: '/pathway-provisioning',
        key: 'pathway-provisioning',
        icon: 'build',
        title: 'Pathways Provisioning',
        accessGroups: ['pathway-provisioning'],
      },
      {
        isInternal: true,
        to: '/configure-alert-banner',
        key: 'configure-alert-banner',
        icon: 'build',
        title: 'Dashboard Banner',
        accessGroups: ['dashboard-banner'],
      },
      {
        isInternal: true,
        to: '/company-industry',
        key: 'company-industry',
        icon: 'bank',
        title: 'Company Industry',
        accessGroups: ['company-industry'],
      },
      {
        isInternal: true,
        to: '/admin-tags',
        key: 'admin-tags',
        icon: 'tags',
        title: 'Admin Tags',
        accessGroups: ['admin-tags'],
      },
      {
        isInternal: true,
        to: '/ab-testing',
        key: 'ab-testing',
        icon: 'question-circle',
        title: 'AB Testing',
        accessGroups: ['ab-testing'],
      },
      {
        isInternal: true,
        to: '/upsell-pages',
        key: 'upsell-pages',
        icon: 'rise',
        title: 'Upsell Landing Pages',
        accessGroups: ['upsell-landing-pages'],
      },
      {
        isInternal: true,
        to: '/groups-programs-tier',
        key: 'groups-programs-tier',
        icon: 'folder',
        title: 'Groups & Program Tiers',
        accessGroups: ['groups-and-program-tiers'],
      },
      {
        isInternal: true,
        to: '/cms-tool',
        key: 'cms-tool',
        icon: 'folder',
        title: 'CMS Tool',
        accessGroups: ['cms-content'],
      },
      {
        isInternal: true,
        to: '/templates-and-tips',
        key: 'templates-and-tips',
        icon: 'deployment-unit',
        title: 'Templates And Tips',
        accessGroups: ['templates-and-tips'],
      },
      {
        isInternal: true,
        to: '/compass',
        key: 'compass',
        icon: 'file-done',
        title: 'Compass',
        accessGroups: ['compass'],
      },
      {
        isInternal: true,
        to: '/sessions',
        key: 'sessions',
        icon: 'security-scan',
        title: 'Session Management',
        accessGroups: ['sessions'],
      },
      {
        isInternal: true,
        to: '/usertype-settings',
        key: 'usertype-settings',
        icon: 'user',
        title: 'UserTypes Provisioning',
        accessGroups: ['user-types-provisioning'],
      },
      {
        isInternal: true,
        to: '/manage-career-paths',
        key: 'manage-career-paths',
        icon: 'rocket',
        title: 'Manage Career Paths',
        accessGroups: ['manage-career-paths'],
      },
      {
        isInternal: true,
        to: '/manage-benchmarking-groups',
        key: 'manage-benchmarking-groups',
        icon: 'desktop',
        title: 'Benchmarking Groups',
        accessGroups: ['manage-benchmarking-groups'],
      },
    ],
  },
  {
    title: 'Feature Management',
    key: 'features',
    icon: 'api',
    subMenu: [
      {
        isInternal: true,
        to: '/features/cluster',
        title: 'Cluster Features',
        key: 'cluster-features',
        accessGroups: ['cluster-features'],
      },
      {
        isInternal: true,
        to: '/features/hub',
        title: 'Hub Features',
        key: 'hub-features',
        accessGroups: ['hub-features'],
      },
    ],
  },
  {
    to: '/seed-databases',
    key: 'seed-databases',
    icon: 'database',
    title: 'Seed Databases',
  },
  {
    title: 'BridgesAlliance',
    key: 'bridgesAlliance',
    icon: 'question-circle',
    subMenu: [
      {
        isInternal: true,
        to: '/bridges-alliance/invited-university',
        title: 'Invited Universities',
        key: 'invited-universities',
        accessGroups: ['bridges-alliance'],
      },
    ],
  },
  {
    to: '/user-detail',
    key: 'user-detail',
    icon: 'idcard',
    title: 'User Detail',
    accessGroups: ['user-detail'],
  },
  {
    to: '/configure-alert-banner',
    key: 'configure-alert-banner',
    icon: 'build',
    title: 'Dashboard Banner',
    accessGroups: ['dashboard-banner'],
  },
  {
    to: '/product-releases',
    key: 'product-releases',
    icon: 'fire',
    title: 'Product Releases',
    accessGroups: ['product-release'],
  },
  {
    title: 'Services',
    key: 'services',
    icon: 'cloud-server',
    subMenu: [
      {
        isInternal: false,
        to: `${process.env.REACT_APP_CONFIG_URL_US}${localStorage.getItem(
          'token'
        )}`,
        title: 'Config Page US',
        key: '1',
        accessGroups: ['us-aus-config-pages'],
      },
      {
        isInternal: false,
        to: `${
          process.env.REACT_APP_CONFIG_URL_AUSTRALIA
        }${localStorage.getItem('token')}`,
        title: 'Config Page Australia',
        key: '2',
        accessGroups: ['us-aus-config-pages'],
      },
    ],
  },
  {
    title: 'Eng Tools',
    key: 'eng-tools',
    icon: 'deployment-unit',
    subMenu: [
      {
        isInternal: true,
        to: '/manage-cache',
        key: 'manage-cache',
        title: 'Manage Cache',
        accessGroups: ['manage-cache'],
      },
      {
        isInternal: true,
        title: 'Refresh ES Document',
        key: 'refresh-es',
        to: '/refresh-es',
        accessGroups: ['refresh-es-documents'],
      },
      {
        isInternal: true,
        to: '/manage-bulk-emails',
        key: 'manage-bulk-emails',
        title: 'Manage Bulk Emails',
        accessGroups: ['manage-bulk-emails'],
      },
      {
        isInternal: true,
        to: '/manage-imports',
        key: 'manage-imports',
        title: 'Manage Imports',
        accessGroups: ['manage-imports'],
      },
    ],
  },
  {
    to: '/feedback',
    key: 'feedback',
    icon: 'info-circle',
    title: 'Feedback',
    accessGroups: ['feedback'],
  },
  {
    to: '/default-text',
    key: 'cluster-hub-text',
    icon: 'edit',
    title: 'Default Texts',
    accessGroups: ['cluster-hub-text'],
  },
  {
    to: '/es-logstash',
    key: 'es-logstash',
    icon: 'cloud-sync',
    title: 'ES Logstash',
    accessGroups: ['es-log-stash'],
  },
  {
    to: '/event-driven-auto-notifications',
    key: 'event-driven-auto-notifications',
    icon: 'cloud-sync',
    title: 'Event Auto Notification',
    accessGroups: ['event-driven-auto-notifications'],
  },
  {
    title: 'Analytics',
    key: 'analytics',
    icon: 'form',
    subMenu: [
      {
        isInternal: true,
        to: '/build-analytics-chart',
        key: 'build-analytics-chart',
        title: 'Analytics Chart',
        accessGroups: ['analytics-reports', 'analytics-reports-readonly'],
      },
      {
        isInternal: true,
        to: '/analytics-reports',
        key: 'analytics-reports',
        title: 'Analytics Reports',
        accessGroups: ['analytics-reports', 'analytics-reports-readonly'],
      },
      {
        isInternal: true,
        to: '/associate-charts',
        key: 'associate-charts',
        title: 'Associate Chart',
        accessGroups: ['analytics-reports', 'analytics-reports-readonly'],
      },
      {
        isInternal: true,
        to: '/analytics-profile',
        key: 'analytics-profile',
        title: 'Analytics Profile',
        accessGroups: ['analytics-profile'],
      },
    ],
  },
  {
    key: 'process-alert-service',
    icon: 'monitor',
    title: 'Process Alert Service',
    to: '/process-alert-service',
    accessGroups: ['process-alert-service'],
  },
  {
    title: 'ML Service',
    key: 'ml-service',
    icon: 'deployment-unit',
    subMenu: [
      {
        isInternal: true,
        to: '/ml/api',
        key: 'ml-api',
        title: 'ML API',
        accessGroups: ['ml-service'],
      },
    ],
  },
];

export default MenuData;
