import React, { useState, useRef } from 'react';
import {
  Button,
  Table,
  Tooltip,
  Row,
  Col,
  Switch,
  Popover,
  Icon,
  Spin,
} from 'antd';

import { useDispatch } from 'react-redux';
import { setFormData } from '../../actions';
import useGetHubListQuery from '../../query/useGetHubListQuery';
import CreateAction from '../CreateAction/CreateAction';
import useActionItemsQuery from '../../query/useActionItemsQuery';
import usePublishActionItemMutation from '../../query/usePublishActionItemMutation';

function ActionsTab() {
  //   const topActionWrapperRef = useRef();
  const [selectedActionItem, setSelectActionItem] = useState();
  const [page, setCurrentPage] = useState(1);
  const [activeForm, setActiveForm] = useState(null);
  const dispatch = useDispatch();

  const { data, isLoading: globalActionLoading } = useActionItemsQuery(page);

  const { mutate: setActionItemActive } = usePublishActionItemMutation({
    page,
    setSelectActionItem,
  });

  useGetHubListQuery();

  if (!data) return <Spin />;

  const { actions: globalActions, totalCount } = data;

  const updateActiveForm = type => {
    setSelectActionItem(null);
    dispatch(setFormData({}));
    setActiveForm(type);
  };

  const handleEdit = record => {
    // topActionWrapperRef.current.scrollIntoView({
    //   behavior: 'smooth',
    // });
    dispatch(setFormData({}));
    setSelectActionItem(record);
  };

  const makeColumnsByTab = () => [
    {
      title: 'Action Name',
      dataIndex: 'title',
      key: 'title',
      width: 291,
      ellipsis: true,
      render: val => (
        <div className="templates-and-tips-ellipsis-cell">
          <Tooltip placement="topLeft" title={val}>
            {val}
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      width: 130,
      key: 'createdAt',
    },
    {
      title: 'Updated on',
      dataIndex: 'updatedAt',
      width: 130,
      key: 'updatedAt',
    },
    {
      title: () => (
        <Popover
          content="Audience and Criteria cannot be updated once the action item is published"
          placement="bottom"
        >
          Published
          <Icon type="info-circle-o" style={{ marginLeft: '8px' }} />
        </Popover>
      ),
      width: 130,
      dataIndex: 'active',
      key: 'active',
      render: (_, record) => (
        <Switch
          checked={record.active}
          onChange={(value, e) => {
            e.stopPropagation();
            setActionItemActive({
              ...record,
              active: value,
            });
          }}
        />
      ),
    },
  ];

  const getTableData = () =>
    globalActions.map(({ id: key, createdAt, updatedAt, ...rest }) => ({
      id: key,
      key,
      createdAt: new Date(createdAt).toLocaleDateString(),
      updatedAt: new Date(updatedAt).toLocaleDateString(),
      ...rest,
    }));

  return (
    <div
      //   ref={el => {
      //     topActionWrapperRef.current = el;
      //   }}
      className="manage-table-container"
    >
      <Col span={12} style={{ background: 'white' }}>
        <Row>
          <Button
            style={{ margin: '12px' }}
            key="create-achievements"
            icon="file-add"
            onClick={() => updateActiveForm('actions')}
            type="primary"
          >
            Create Action
          </Button>
        </Row>
        <Row>
          <Table
            key={1}
            rowClassName={record =>
              record.id === selectedActionItem?.id ? 'selected-row' : ''
            }
            dataSource={getTableData()}
            scroll={{ x: 500 }}
            loading={globalActionLoading}
            columns={makeColumnsByTab()}
            pagination={{
              simple: false,
              total: totalCount,
              pageSize: 10,
              current: page,
            }}
            onChange={({ current }) => setCurrentPage(current)}
            onRow={record => ({ onClick: () => handleEdit(record) })}
          />
        </Row>
      </Col>
      <Col span={12}>
        {(selectedActionItem || activeForm) && (
          <CreateAction
            selectedActionItem={selectedActionItem}
            setSelectActionItem={setSelectActionItem}
            page={page}
          />
        )}
      </Col>
    </div>
  );
}

export default ActionsTab;
