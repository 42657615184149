import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { get, intersection, isEmpty } from 'lodash';

export const getLocaleDateTimeString = timeString => {
  const a = new Date(timeString);

  if (a && a.toLocaleString) {
    return a.toLocaleString();
  }
  return '';
};

export const isValidUrl = url => {
  if (isEmpty(url)) return false;
  const re =
    /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,15}(\/[a-zA-Z0-9#=_\-\/?&.]*)?$/;
  return re.test(url);
};

export const isValidImageUrl = url => {
  if (isEmpty(url)) return false;
  const re =
    /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,15}(\/[a-zA-Z0-9#=_\-%\/?&.\(\) ]*)?$/;
  return re.test(url);
};

export const breadCrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={route.path}>{route.breadcrumbName}</Link>
  );
};

export const isValidHubIdentifier = str => {
  if (str && /^[A-Za-z0-9-]*$/gi.test(str)) {
    return true;
  }
  return false;
};

export const isAnalyticsColumnsInvalid = tableColumns => {
  const invalidColumns = tableColumns.filter(item => !item.dataType);
  return invalidColumns.length > 0;
};

export const standardUserTypeOptions = [
  {
    value: 'Student',
    name: 'Student',
  },
  {
    value: 'Alumni',
    name: 'Alumni',
  },
  {
    value: 'Admin',
    name: 'Admin',
  },
  {
    value: 'Advisor',
    name: 'Advisor',
  },
  {
    value: 'Restricted',
    name: 'Restricted',
  },
  {
    value: 'Graduate Student',
    name: 'Graduate Student',
  },
  {
    value: 'Corporate Partner & Employer',
    name: 'Corporate Partner & Employer',
  },
  {
    value: 'Prospective Student',
    name: 'Prospective Student',
  },
  {
    value: 'Faculty & Staff',
    name: 'Faculty & Staff',
  },
  {
    value: 'Family & Parent',
    name: 'Family & Parent',
  },
  {
    value: 'Friend of',
    name: 'Friend of',
  },
  {
    value: 'Guest Access',
    name: 'Guest Access',
  },
  {
    value: 'Alumni Ambassador',
    name: 'Alumni Ambassador',
  },
  {
    value: 'Student Ambassador',
    name: 'Student Ambassador',
  },
  {
    value: 'Student Leader',
    name: 'Student Leader',
  },
  {
    value: 'Other',
    name: 'Other',
  },
];

export const emailRegexValidator = email => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
  return regex.test(email);
};

export const isValidString = value => !!(value && value.trim());

export const defaultUsertypeTemplateSettings = {
  title: {
    key: 'title',
    name: 'User Type',
    type: 'text',
    visible: true,
    editable: false,
  },
  defaultUserHeadlineText: {
    key: 'defaultUserHeadlineText',
    name: 'Default Headline',
    type: 'text',
    visible: true,
    editable: true,
  },
  canOfferHelp: {
    key: 'canOfferHelp',
    name: 'Offer Help',
    type: 'boolean',
    visible: true,
    editable: false,
  },
  canBeMatched: {
    key: 'canBeMatched',
    name: 'Seek Help',
    type: 'boolean',
    visible: true,
    editable: false,
  },
  matchUserTypes: {
    key: 'matchUserTypes',
    name: 'Seek Help From',
    type: 'userTypes',
    visible: true,
    editable: false,
  },
  visibleInSignup: {
    key: 'visibleInSignup',
    name: 'Visibility in Signup',
    type: 'boolean',
    visible: true,
    editable: true,
  },
  defaultLocation: {
    key: 'defaultLocation',
    name: 'Default Location',
    type: 'location',
    visible: true,
    editable: false,
  },
  defaultSchool: {
    key: 'defaultSchool',
    name: 'Default School',
    type: 'school',
    visible: true,
    editable: false,
  },
  isSSOVerificationRequied: {
    key: 'isSSOVerificationRequied',
    name: 'Require SSO Signup',
    type: 'boolean',
    visible: true,
    editable: true,
  },
  signupApprovalRequired: {
    key: 'signupApprovalRequired',
    name: 'Approval Required',
    type: 'boolean',
    visible: true,
    editable: true,
  },
  activeModules: {
    key: 'activeModules',
    name: 'Tabs',
    type: 'activeModules',
    visible: true,
    editable: false,
  },
  canPostDiscussion: {
    key: 'canPostDiscussion',
    name: 'Allow Participation in Discussions/Forums',
    type: 'boolean',
    visible: true,
    editable: false,
  },
};

export const isStandardHubCluster = object =>
  [
    'standard',
    'transitioned-standard',
    'standard-plus',
    'standard-plus-fpu',
  ].includes(get(object, 'standardPlatformType', 'enterprise'));

export const getTimezoneList = () => moment.tz.names();

export const accessGroupsVerified = (admin, allowedAccessGroups) => {
  if (Array.isArray(allowedAccessGroups) && allowedAccessGroups.length) {
    const adminAccessGroups = get(admin, 'adminAccessGroups', []);
    if (
      Array.isArray(adminAccessGroups) &&
      (intersection(adminAccessGroups, allowedAccessGroups).length ||
        adminAccessGroups.indexOf('full-access') > -1)
    ) {
      return true;
    }
    return false;
  }
  const adminAccessGroups = get(admin, 'adminAccessGroups', []);
  // for full access users show all pages
  if (adminAccessGroups.indexOf('full-access') > -1) {
    return true;
  }
  return false; // Default don't show pages for any types of users except full access
};
