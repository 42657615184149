import React, { Fragment, PureComponent } from 'react';
import { Button, Icon } from 'antd';
import FeedbacksTable from './components/FeedbacksTable';

class MentorshipFeedback extends PureComponent {
  // eslint-disable-next-line react/sort-comp
  componentDidMount() {
    const {
      data: { data },
      fetchData,
    } = this.props;
    if (!data) {
      fetchData();
    }
  }

  columns = [
    {
      title: 'Cluster',
      key: 'cluster',
      dataIndex: 'cluster',
      width: '10%',
      render: cluster => (
        <>
          <p>ID - {cluster.id}</p>
          <p>{cluster.name}</p>
        </>
      ),
    },
    {
      title: 'Hub',
      key: 'hub',
      dataIndex: 'hub',
      width: '10%',
      render: hub => (
        <>
          <p>ID - {hub.id}</p>
          <p>{hub.name}</p>
        </>
      ),
    },
    {
      title: 'From User',
      key: 'from_user',
      dataIndex: 'fromUser',
      width: '10%',
      render: fromUser => (
        <span>
          {fromUser.firstName} {fromUser.lastName}
        </span>
      ),
    },
    {
      title: 'To User',
      key: 'to_user',
      dataIndex: 'toUser',
      width: '10%',
      render: toUser => (
        <span>
          {toUser.firstName} {toUser.lastName}
        </span>
      ),
    },
    {
      title: 'Feedback',
      key: 'feedback',
      dataIndex: 'feedbackObject',
      width: '20%',
      render: feedbackObject => (
        <>
          {feedbackObject.map((feedback, index) => (
            <div
              style={{ maxHeight: 150, overflowY: 'auto', padding: '10px' }}
              key={index}
            >
              <p style={{ fontWeight: 'bold' }}>{feedback.question}</p>
              {feedback.response ? (
                <p style={{ textAlign: 'justify' }}>{feedback.response}</p>
              ) : null}
            </div>
          ))}
        </>
      ),
    },
    {
      title: 'Consent',
      key: 'consentGiven',
      dataIndex: 'consentGiven',
      width: '10%',
      render: consentGiven => {
        if (consentGiven) return <span>True</span>;
        if (consentGiven === null) {
          return (
            <span
              style={{
                display: 'inline-block',
                textAlign: 'center',
                width: '100%',
              }}
            >
              -
            </span>
          );
        }
        return <span>False</span>;
      },
    },
    {
      title: 'Rating',
      key: 'rating',
      dataIndex: 'rating',
      width: '5%',
      render: rating => (rating ? <span>{rating}/5</span> : null),
    },
    {
      title: 'Start / End',
      key: 'start_end',
      dataIndex: 'startEndDate',
      width: '5%',
      render: startEndDate => <span>{startEndDate}</span>,
    },
    {
      title: 'Endorsements',
      key: 'endorsements',
      dataIndex: 'endorsements',
      width: '15%',
      render: endorsements => {
        if (
          !endorsements ||
          !Array.isArray(endorsements) ||
          !endorsements.length
        ) {
          return null;
        }
        return (
          <>
            {endorsements.map(x => (
              <div
                style={{
                  wordBreak: 'keep-all',
                  whiteSpace: 'nowrap',
                  lineHeight: '24px',
                  margin: '4px 0',
                }}
              >
                {x.label}
                <br />
              </div>
            ))}
          </>
        );
      },
    },
    {
      title: 'Date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: '5%',
      render: createdAt => <span>{createdAt}</span>,
    },
    {
      title: 'Open Hub',
      key: 'action',
      dataIndex: 'hub',
      width: '5%',
      render: hub => (
        <Button type="primary" onClick={() => this.props.openHub(hub.id)}>
          <Icon type="login" />
        </Button>
      ),
    },
  ];

  paginationOptions = {
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '15', '25', '50'],
  };

  handlePaginationChange = (page, pageSize) => {
    const { setPage, setPageSize, fetchData } = this.props;
    setPage(page);
    setPageSize(pageSize);
    fetchData({ limit: pageSize, page });
  };

  handleTableChange = ({ current: page, pageSize }) => {
    this.handlePaginationChange(page, pageSize);
  };

  render() {
    const {
      data: { data, current, total, limit, loading },
    } = this.props;
    const pagination = {
      ...this.paginationOptions,
      current,
      pageSize: limit,
      total,
      showTotal: (isTotal, range) => `${range[0]} to ${range[1]} of ${isTotal}`,
    };
    return (
      <FeedbacksTable
        data={data}
        loading={loading}
        columns={this.columns}
        pagination={pagination}
        onChange={this.handleTableChange}
      />
    );
  }
}

export default MentorshipFeedback;
