import React, { PureComponent } from 'react';
import { Table } from 'antd';

class FeedbacksTable extends PureComponent {
  render() {
    const { columns, loading, data, pagination, onChange } = this.props;
    return (
      <Table
        pagination={pagination}
        dataSource={data}
        loading={loading}
        columns={columns}
        onChange={onChange}
        bordered
        size="small"
        scroll={{ x: true }}
      />
    );
  }
}

export default FeedbacksTable;
